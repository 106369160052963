body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'neutra_displaytitling';
  src: url('./fonts/neutra_display_titling-webfont.woff2') format('woff2'),
  url('./fonts/neutra_display_titling-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'avenir_next_lt_proregular';
  src: url('./fonts/avenir_next_lt_pro_regular-webfont.woff2') format('woff2'),
  url('./fonts/avenir_next_lt_pro_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_lt_probold';
  src: url('./fonts/avenir_next_lt_pro_bold-webfont.woff2') format('woff2'),
  url('./fonts/avenir_next_lt_pro_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


.hero-circle {
  @apply block absolute z-0 left-[-40vw] lg:left-auto lg:right-[-120px] bottom-[30px] lg:bottom-0 w-[120vw] h-full lg:w-[1548px] lg:h-[1548px] rounded-[150px] lg:rounded-full;
  pointer-events: none;
  background: linear-gradient(26deg, #F4EB2C 11.14%, #FAA638 39.41%, #E30685 70.79%);
}
.footer-circle {
  @apply block z-0 top-0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  border-radius: 100%;
  height: 2084px;
  width: 2084px;
  background: linear-gradient(0deg, #F4EB2C 79.1%, #FAA638 85.31%, #E30685 100%);
}
.sustainability-circle {
  @apply flex flex-col lg:flex-row justify-center items-center rounded-full py-[71px] px-[36px] lg:w-[770px] lg:h-[770px];

  background: linear-gradient(180deg, #F4EB2C 0%, #FAA638 47.4%, #E30685 100%);
}

.swiper-pagination-bullet {
  @apply font-neutra text-[23px] leading-[40px];
  width: 40px;
  height: 40px;
  text-align: center;
  opacity: 1;
  color: #E30685;
  background: #F5F5F5;
}

.swiper-pagination-bullet-active {
  background: #E30685;
  color: #F5F5F5;
}
.play-btn {
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 24.2%), linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%), #E30685;
  background-blend-mode: normal, multiply, normal;
}
.play-btn:hover {
  background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 24.2%), linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%), #E30685;
}
.modal-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 400px;*/
  background-color: #f8f8f8; /* Replace with your background color */
  border: 2px solid #000;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  padding: 0;
}
.inputcircle {
  @apply aspect-square w-full absolute z-0 top-0 rounded-full hidden lg:block left-0 bg-[#F5F5F5];

}
.categories .active img{
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.50);
}
.active-product{
  filter: drop-shadow(0px 0px 20px rgba(3, 37, 77, 0.4));
}

.handle-bullet {
  @apply bg-strawberry-100 text-white w-fit text-[16px] font-neutra uppercase py-[8px] px-[10px] rounded-full absolute;
  white-space: nowrap;
  top: 0;
}
.handle-bullet::after {
  position: absolute;
  bottom: -15px;
  right: 10px;
  content: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6598 0.90806L22.7042 11.1906L11.6598 21.4731L0.615314 11.1906L11.6598 0.90806Z' fill='%23E30685'/%3E%3C/svg%3E%0A");
}

/* Range Slider Container */
.road {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #E30685;
}


.swiper-slide-thumb-active .thumb-title{
  @apply text-strawberry-100;
}

.swiper-button-prev{
  @apply left-[32px] lg:left-[22px];
}


.custom-prev::after {

  content: url("data:image/svg+xml,%3Csvg width='71' height='69' viewBox='0 0 71 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.5 63.4573C35.5 65.2002 33.2997 66.0731 32.0129 64.8407L1.4444 35.5638C0.64668 34.7998 0.64668 33.5611 1.4444 32.7971L32.0129 3.52016C33.2997 2.28771 35.5 3.16059 35.5 4.90354L35.5 63.4573Z' fill='%23E30685'/%3E%3C/svg%3E%0A");
}
.custom-next::after {

  content: url("data:image/svg+xml,%3Csvg width='71' height='69' viewBox='0 0 71 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.5 4.90367C35.5 3.16072 37.7003 2.28784 38.9871 3.52029L69.5556 32.7972C70.3533 33.5612 70.3533 34.7999 69.5556 35.5639L38.9871 64.8408C37.7003 66.0733 35.5 65.2004 35.5 63.4574L35.5 4.90367Z' fill='%23E30685'/%3E%3C/svg%3E%0A");
}
/*.swiper-button-disabled {opacity: .3;}*/

.lineheight-normal {line-height: normal;}
.swiper-button-disabled {
  @apply opacity-30 lg:opacity-100;
}